// 沿着坐标运动
import * as Cesium from "cesium";

interface Iposition {
    longitude: number,
    latitude: number,
    height: number,
}
export default class PositionMovement {

    viewer: Cesium.Viewer
    positionList: Iposition[]

    constructor(viewer: Cesium.Viewer, positionList: Iposition[]) {
        this.viewer = viewer
        this.positionList = positionList
    }

    start() {
        // 时间间隔 30秒
        const timeStepInSeconds = 30;

        // 整个飞行花费的时间
        const totalSeconds = (this.positionList.length - 1) * timeStepInSeconds;

        // 设置起点时间
        const time = new Date("2022-10-23T23:21:59Z");

        // cesium，默认使用的是儒略日的时间
        // 所以需要起点时间转换成儒略日的时间
        const startJulianDate = Cesium.JulianDate.fromDate(time);
        // 设置终点时间
        const stopJulianDate = Cesium.JulianDate.addSeconds(
            startJulianDate,
            totalSeconds,
            new Cesium.JulianDate()
        );

        // 将查看器的时间调整到起点和结束点的范围
        this.viewer.clock.startTime = startJulianDate.clone();
        this.viewer.clock.stopTime = stopJulianDate.clone();
        this.viewer.clock.currentTime = startJulianDate.clone();
        // 调整时间线为 从起点到开始,这里需要在设置里开启timeline：true
        this.viewer.timeline.zoomTo(startJulianDate, stopJulianDate);

        // 用于往每个坐标点里添加时间
        const positionProperty = new Cesium.SampledPositionProperty();

        this.positionList.forEach((item, i) => {
            // 当前点的时间
            const time = Cesium.JulianDate.addSeconds(
                startJulianDate,
                i * timeStepInSeconds,
                new Cesium.JulianDate()
            );

            // 设置当前点的位置
            const position = Cesium.Cartesian3.fromDegrees(
                item.longitude,
                item.latitude,
                item.height
            );
            // 添加轨迹采样点
            positionProperty.addSample(time, position);

            // 添加点
            // this.viewer.entities.add({
            //     name: "轨迹点",
            //     position: position,
            //     point: {
            //         pixelSize: 10,
            //         color: Cesium.Color.RED,
            //         outlineColor: Cesium.Color.WHITE,
            //         outlineWidth: 2,
            //     },
            // });
        });

        // 创建飞机
        const planeEntity = this.viewer.entities.add({
            availability: new Cesium.TimeIntervalCollection([
                new Cesium.TimeInterval({
                    start: startJulianDate,
                    stop: stopJulianDate,
                }),
            ]),
            name: "飞机",
            // 设置飞机的可用
            position: positionProperty,
            // VelocityOrientationProperty会自动根据采样点，计算出飞机的速度和方向
            orientation: new Cesium.VelocityOrientationProperty(positionProperty),
            model: {
                uri: "./model/Air.glb",
                minimumPixelSize: 128,
                // maximumScale: 20000,
            },
            // 绘制轨迹线
            path: new Cesium.PathGraphics({
                width: 2,
            }),
        });

        // 相机追踪运动的问题
        this.viewer.trackedEntity = planeEntity;

        // 设置时间速率
        this.viewer.clock.multiplier = 10;

        // 循环运动
        this.viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP
    }

    clear() {
        this.viewer.entities.removeAll()
        // 时间执行完后停止
        this.viewer.clock.clockRange = Cesium.ClockRange.CLAMPED
    }
}