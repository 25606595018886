
import * as Cesium from "cesium";
/*
鼠标点击获取经纬度
*/
class MousePosition {
  constructor(viewer:Cesium.Viewer) {

    //   监听鼠标的移动事件
    const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);

    handler.setInputAction((movement:any) => {
      //   获取鼠标的坐标
      const cartesian = viewer.camera.pickEllipsoid(
        movement.position,
        viewer.scene.globe.ellipsoid
      );
      if (cartesian) {
        //   转换成经纬度
        const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
        const longitude = Cesium.Math.toDegrees(
          cartographic.longitude
        ).toFixed(4);
        const latitude = Cesium.Math.toDegrees(
          cartographic.latitude
        ).toFixed(4);
        const height = cartographic.height;
        //   显示经纬度
        // console.log(
        //   `经度：${longitude} 纬度：${latitude} 高度：${height}`
        // );
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
  }
}

export default  MousePosition