// 调用地图

import * as Cesium from "cesium"

// 百度地图纠偏
import BaiduImageryProvider from '@/cesium/mapDeviation/baidu/BaiduImageryProvider.js'

// 高德地图纠偏
import transformProjection from '@/cesium/mapDeviation/autonavi/transformProjection.js'
const {UrlTemplateImageryProvider, WebMercatorTilingScheme} = Cesium

export enum EMap {
	tianditut_vec = "tianditut_vec", //天地图矢量地图
	tiandituTdt_img = "tianditut_img", //天地图影像
	tiandituTdt_ter = "tianditut_ter",  //天地图地形
	tiandituTdt_cva = "tianditut_cva", //天地图矢量地图标记
	tiandituTdt_cia = "tianditut_cia", //天地图影像地图标记
	tiandituTdt_cta = "tianditut_cta", //天地图地形标记

	osm = "osm",

	autonavi_autonavi = "autonavi_autonavi",  //高德矢量地图
	autonavi_img = "autonavi_img",  //高德影像地图
	autonavi_cia = "autonavi_cia", //高德影像地图标记

	baidu_img="baidu_img",
	baidu_imgLabel="baidu_imgLabel",
	baidu_normal="baidu_normal",
	baidu_dark="baidu_dark",

	bing_img="bing_img"
}
export function map(mapType: EMap) {
	// 天地图矢量地图
	if (mapType.slice(0,9)=="tianditut") {
		return new Cesium.WebMapTileServiceImageryProvider({
			url: `https://t0.tianditu.gov.cn/${mapType.slice(10)}_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=${mapType.slice(10)}&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=4bd8d216a6ca94ded3d525970dfa02fd`,
			layer: "tdtBasicLayer",
			style: "default",
			format: "image/jpeg",
			tileMatrixSetID: "GoogleMapsCompatible",
		})
	}

	else if (mapType == EMap.osm) {
		return new Cesium.OpenStreetMapImageryProvider({
			url: "https://a.tile.openstreetmap.org/",
		})
	}
	
	// 高德矢量地图
	else if (mapType.slice(0,8)=="autonavi" && mapType.slice(9)=="autonavi") {
		let provider = new UrlTemplateImageryProvider({
			url: "https://webst02.is.autonavi.com/appmaptile?style=7&x={x}&y={y}&z={z}",
			tilingScheme: new WebMercatorTilingScheme(),
			maximumLevel: 20,
		});
		provider.readyPromise.then(() => {
			transformProjection(provider);
		});
		return provider;
	}
	// 高德影像地图
	else if (mapType.slice(0,8)=="autonavi" && mapType.slice(9)=="img") {
		let provider = new UrlTemplateImageryProvider({
			url: "https://webst02.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}",
			tilingScheme: new WebMercatorTilingScheme(),
			maximumLevel: 20,
		});
		provider.readyPromise.then(() => {
			transformProjection(provider);
		});
		return provider;
	} 
	// 高德影像标准
	else if (mapType.slice(0,8)=="autonavi" && mapType.slice(9)=="cia") {
		let provider = new UrlTemplateImageryProvider({
			url: "https://webst02.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=8",
			tilingScheme: new WebMercatorTilingScheme(),
			maximumLevel: 20,
		});
		provider.readyPromise.then(() => {
			transformProjection(provider);
		});
		return provider;
	}

	// baidu地图
	else if(mapType.slice(0,5)=='baidu'){
		return new BaiduImageryProvider({
			style: mapType.slice(6), // style: img、imgLabel、normal、dark
			crs: "WGS84", // 使用84坐标系，默认为：BD09
		})
	}

	else{
		const imageryProvider =  new Cesium.BingMapsImageryProvider({
			url : 'https://dev.virtualearth.net',
			key : 'At3TD3P6FF_jKpL11EH3Qy2LzJZplWcIvtBp6xLfu_XVPbK_8W0kUArVUAOeSDGv',
			mapStyle : Cesium.BingMapsStyle.AERIAL
		});

		return imageryProvider
	}
}