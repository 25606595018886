import * as Cesium from "cesium"

export function mapLoading(viewer:Cesium.Viewer) {
    var helper = new Cesium.EventHelper();
    helper.add(viewer.scene.globe.tileLoadProgressEvent, function (e) {
        if (e == 0) {
            // console.log("矢量切片加载完成时的回调");
            
        }
    });
}
