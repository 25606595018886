// 地图分屏
import { EMap, map } from "@/cesium/map"

export default class MapContrast {

    Cesium
    viewer
    isOpen
    layer: any 
    layerDiv: any

    constructor(Cesium: any, viewer: any,) {
        this.viewer = viewer
        this.Cesium = Cesium
        this.isOpen = false
    }

    // 创建卷帘
    createLayer() {
        this.isOpen = true
        if(this.viewer.imageryLayers._layers[0]._imageryProvider.url== "https://dev.virtualearth.net/"){
            this.layer = this.viewer.imageryLayers.addImageryProvider(map(EMap.autonavi_autonavi));
            
        }else{
            this.layer = this.viewer.imageryLayers.addImageryProvider(map(EMap.bing_img));
        }
        
        this.viewer.scene.splitPosition = 0.5;
        this.layer.splitDirection = this.Cesium.SplitDirection.LEFT
        // 创建卷帘div
        this.createLayerDiv()
    }

    // 创建卷帘div
    createLayerDiv() {
        this.layerDiv = document.createElement('div');
        this.layerDiv.className = "layerDiv"
        this.layerDiv.style.cssText = `
            position: absolute;
            left: 50%;
            top: 0px;
            background-color: #D3D3D3;
            width: 5px;
            height: 100%;
            z-index: 9999;
            cursor:move;
        `

        // 监听卷帘div移动
        this.viewer.container.appendChild(this.layerDiv);
        const handler = new this.Cesium.ScreenSpaceEventHandler(this.layerDiv);
        handler.setInputAction(() => {
            this.isOpen = true;
        }, this.Cesium.ScreenSpaceEventType.LEFT_DOWN);

        handler.setInputAction(() => {
            this.isOpen = true;
        }, this.Cesium.ScreenSpaceEventType.PINCH_START);

        handler.setInputAction((movement: any) => {
            this.updateDivPosition(movement)
        }, this.Cesium.ScreenSpaceEventType.MOUSE_MOVE);

        handler.setInputAction((movement: any) => {
            this.updateDivPosition(movement)
        }, this.Cesium.ScreenSpaceEventType.PINCH_MOVE);

        handler.setInputAction(() => {
            this.isOpen = false;
        }, this.Cesium.ScreenSpaceEventType.LEFT_UP);
        handler.setInputAction(() => {
            this.isOpen = false;
        }, this.Cesium.ScreenSpaceEventType.PINCH_END);
    }

    // 鼠标移动时触发
    updateDivPosition(movement: any) {
        if (!this.isOpen) {
            return
        }
        var relativeOffset = movement.endPosition.x;
        var splitPosition = (this.layerDiv.offsetLeft + relativeOffset) / this.layerDiv.parentElement.offsetWidth;
        this.layerDiv.style.left = 100.0 * splitPosition + '%';
        this.viewer.scene.splitPosition = splitPosition;
    }

    // 清除卷帘
    clearLayer(){
        try {
            this.layerDiv.parentNode.removeChild(this.layerDiv);
            this.viewer.scene.imageryLayers.remove(this.layer);
            this.layer.splitDirection = this.Cesium.SplitDirection.NONE
            this.isOpen = false;
        } catch (error) {
            
        }
    }
}