// 高德地图纠偏


import { wgs84togcj02, gcj02towgs84 } from "coordtransform";
import { Math as CesiumMath, Cartographic } from "cesium";

export default function transformProjection(provider) {
	let webMercatorTilingScheme = provider.tilingScheme;
	let projection = webMercatorTilingScheme._projection;
	projection.x_project = projection.project;
	projection.project = function (cartographic) {
		let point;
		return (
			(point = wgs84togcj02(CesiumMath.toDegrees(cartographic.longitude), CesiumMath.toDegrees(cartographic.latitude))),
			projection.x_project(new Cartographic(CesiumMath.toRadians(point[0]), CesiumMath.toRadians(point[1])))
		);
	};
	projection.x_unproject = projection.unproject;
	projection.unproject = function (cartesian) {
		let point,
			cartographic = projection.x_unproject(cartesian);
		return (
			(point = gcj02towgs84(CesiumMath.toDegrees(cartographic.longitude), CesiumMath.toDegrees(cartographic.latitude))),
			new Cartographic(CesiumMath.toRadians(point[0]), CesiumMath.toRadians(point[1]))
		);
	};
	return provider;
}